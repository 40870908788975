<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                    class="wizard wizard-2"
                    id="kt_wizard_v2"
                    data-wizard-state="step-first"
                    data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <div class="row">
                            <div class="col-6">
                                <h4>
                                    {{ userGroupId ? 'Edit' : 'Add' }} {{groupName}}
                                </h4>
                                <router-link :to="{ name: 'dashboard' }">
                                Dashboard \
                                </router-link>
                                <router-link :to="{name: 'user-group'}">
                                  {{groupName}}
                                </router-link>

                            </div>
                        </div>

                        <div class="row">
                            <v-col cols="12" md="12">
                                <v-text-field
                                    outlined
                                    dense
                                    :error="$v.userGroup.title.$error"
                                    v-model="userGroup.title"
                                >
                                    <template v-slot:label>Title <span class="text-danger">*</span></template>
                                </v-text-field>
                                <span class="text-danger" v-if="$v.userGroup.title.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.title">{{ errors.title[0] }}</div>
                            </v-col>

                            <v-col cols="12" md="12">
                                <label>
                                    Description <span class="text-danger"></span>
                                </label>
                                <ckeditor
                                    v-model="userGroup.description"
                                    :config="editorConfig"
                                ></ckeditor>
                                <span class="text-danger" v-if="$v.userGroup.description.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.description">{{ errors.description[0] }}</div>

                            </v-col>
                        </div>

                        <div class="row">
                            <div class="col-12 text-right">
                                <v-btn
                                    class="text-gray btn btn-standard"
                                    depressed
                                    @click="cancel"
                                >Cancel
                                </v-btn>
                                <v-btn
                                    class="text-white ml-2 btn btn-primary"
                                    depressed
                                    @click="createOrUpdate"
                                    :loading="isBusy"
                                    v-if="checkIsAccessible('user-group', 'create') || checkIsAccessible('user-group', 'edit')"
                                >
                                  {{userGroupId ? "Update" : "Save"}}
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import UserGroupService from "@/core/services/user/user-group/UserGroupService";

const userGroup = new UserGroupService();

export default {
    validations: {
        userGroup: {
            title: {required},
            description: {},
        }
    },
    data() {
        return {
            isBusy: false,
            editorConfig: {
              versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            userGroup: {
                title: '',
                description: '',
            },
            errors: [],
        };
    },
    mounted(){
        if(this.userGroupId){
            this.getUserGroup();
        }
    },
    computed:{
        userGroupId(){
            return this.$route.params.userGroupId;
        }
    },
    methods: {
        cancel(){
            this.$tabs.close();
        },
        createOrUpdate() {
            this.$v.userGroup.$touch();
            if (this.$v.userGroup.$error) {
                setTimeout(() => {
                    this.$v.userGroup.$reset();
                }, 3000);
            } else {
                if (this.userGroupId) {
                    this.updateUserGroup();
                } else {
                    this.createUserGroup();
                }
            }
        },
        updateUserGroup() {
            this.isBusy = true;
            userGroup
            .update(this.userGroup.id, this.userGroup)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.$tabs.close();
                this.resetForm();
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createUserGroup() {
            this.isBusy = true;
            userGroup
            .store(this.userGroup)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$snotify.success("Information added");
                this.$tabs.close();
            })
            .catch(error => {
                this.isBusy = false;
                this.errors = error.errors;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.userGroup.$reset();
            this.userGroup = {
                title: '',
                description: ''
            };
        },
        getUserGroup(){
            userGroup
            .show(this.userGroupId)
            .then((res) => {
                this.userGroup = res.data.userGroup;
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        }
    }
}
</script>
